import React, { useCallback, useMemo, useState } from "react";
import { Card, CardBody, Collapse } from "reactstrap";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const INSPECTION_STATUS = {
  IN_PROGRESS: "In Progress",
  COMPLETED: "Completed",
};

const CrewWorkDayTemplatesWO = ({ children, crew, templateWorkOrder }) => {
  const [isOpen, setIsOpen] = useState(true);

  const handleCollapse = useCallback(
    ($event) => {
      $event.stopPropagation();
      setIsOpen(!isOpen);
    },
    [isOpen]
  );

  const groupByStatus = useMemo(() => {
    return templateWorkOrder.inspections.reduce((acc, inspection) => {
      if (!acc[inspection.status]) {
        acc[inspection.status] = [];
      }
      acc[inspection.status].push(inspection);
      return acc;
    }, {});
  }, [templateWorkOrder]);

  const textByStatus = useMemo(() => {
    return Object.keys(groupByStatus)
      .map((status) => {
        return `${groupByStatus[status].length} ${INSPECTION_STATUS[status]}`;
      })
      .join(", ");
  }, [groupByStatus]);

  return (
    <Card className="w-100 px-0">
      <CardBody>
        <div
          onClick={handleCollapse}
          className="cursor-pointer p-3 d-flex justify-content-between align-items-center"
        >
          <div className="d-flex align-items-center gap-5 col-md-10">
            <h2 className="text-truncate w-auto" style={{maxWidth: "75%"}}>
              {templateWorkOrder.name} - {templateWorkOrder.template.name}
            </h2>
            <h5 className="w-25">{textByStatus}</h5>
          </div>
          <div className="col-md-2 text-end">
            <small className="text-primary fw-bold" data-testid="collapse-text">
              View {isOpen ? "less" : "more"}
            </small>
            <FontAwesomeIcon
              className={`ms-2 ${isOpen ? "" : "rotate"}`}
              icon={faChevronDown}
            />
          </div>
        </div>
        <Collapse isOpen={isOpen}>{children}</Collapse>
      </CardBody>
    </Card>
  );
};

export default CrewWorkDayTemplatesWO;
