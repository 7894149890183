import React, { useCallback } from "react";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import GroupItem from "./GroupItem";
import { useInspectionTemplate } from "../providers/inspectionTemplateProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import SortableWrapper from "./SortableWrapper";

const Groups = () => {
  const {
    getGroups,
    state: { activeTab },
    addGroup,
    updateGroupsOrder,
  } = useInspectionTemplate();
  const groups = getGroups(activeTab);
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragEnd = useCallback(
    ($event) => {
      const { active, over } = $event;
      if (active.id !== over.id) {
        const oldIndex = groups.findIndex((item) => item.id === active.id);
        const newIndex = groups.findIndex((item) => item.id === over.id);
        const newItems = arrayMove(groups, oldIndex, newIndex);
        updateGroupsOrder(newItems);
      }
    },
    [updateGroupsOrder, groups]
  );

  const handleAddGroup = useCallback(() => {
    addGroup(activeTab);
  }, [addGroup, activeTab]);

  return (
    <Card className="mb-3 w-100 box-shadow-none">
      <CardBody className="d-flex flex-column gap-3 p-4">
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
          modifiers={[restrictToVerticalAxis]}
        >
          <SortableContext
            items={groups.map((group) => group.id)}
            strategy={verticalListSortingStrategy}
          >
            {groups.map((group, index) => (
              <SortableWrapper id={group.id} index={index} key={group.id}>
                <GroupItem key={group.id} group={group} />
              </SortableWrapper>
            ))}
          </SortableContext>
          <Row>
            <Col className="d-grid gap-2 add-btn-container">
              <Button
                size="sm"
                color="white"
                className="btn-dashed"
                onClick={handleAddGroup}
              >
                <FontAwesomeIcon icon={faCirclePlus} size="xl" /> Add Group
              </Button>
            </Col>
          </Row>
        </DndContext>
      </CardBody>
    </Card>
  );
};

export default Groups;
