import { createRoot } from "react-dom/client";
import moment from "moment";
import FileSaver from "file-saver";
import { toast } from "react-toastify";

import { toastConfig } from "../api/Api.hooks";
import { awsApi } from "../api/awsServices";

const ENV_BETA = "beta";
const ENV_PROD = "production";

const STATUS_APPROVED = "approved";
const STATUS_DECLINED = "declined";

const IS_APP_USER = "IS_APP_USER";

export const sharedHelper = {
  toolTipContent: (times) => `
    <table class="mt-2 mb-n2">
      <thead>
        <tr>
          <td class="text-center"><strong>Start Time</strong></td>
          <td class="text-center"><strong>End Time</strong></td>
          <td class="text-center"><strong>Hours</strong></td>
        </tr>
      </thead>
      <tbody>
        ${times
      .map(
        (time, index) => `
            <tr>
              <td class="text-center ${index === times.length - 1 ? "" : "pb-0"
          }">
                ${sharedHelper.formatDateTime(time.startTime, "MM/DD, h:mm a")}
              </td>
              <td class="text-center ${index === times.length - 1 ? "" : "pb-0"
          }">
                ${time.endTime
            ? sharedHelper.formatDateTime(time.endTime, "MM/DD, h:mm a")
            : "In Progress"
          }
              </td>
              <td class="text-center ${index === times.length - 1 ? "" : "pb-0"
          }">
                ${time.endTime
            ? (
              moment(time.endTime).diff(
                moment(time.startTime),
                "minutes"
              ) / 60
            ).toFixed(2)
            : "-"
          }
              </td>
            </tr>
          `
      )
      .join("")}
      </tbody>
    </table>
  `,
  formatDateTimes: (data) =>
    typeof data === "string"
      ? data
        .replace(
          /(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})\.\d{3}Z/g,
          (match, year, month, day, hour, minute, second) => {
            const date = new Date(
              Date.UTC(year, month - 1, day, hour, minute)
            );
            const options = {
              month: "2-digit",
              day: "2-digit",
              year: "numeric",
              hour: "numeric",
              minute: "numeric",
              hour12: true,
            };
            return date.toLocaleString("en-US", options).replace(",", "");
          }
        )
        .replace(/(\d{4})-(\d{2})-(\d{2})/g, "$2/$3/$1")
      : data,
  renderComponentToNode: (Component, props) => {
    const node = document.createElement("div");
    const root = createRoot(node);
    root.render(<Component {...props} />);
    return node;
  },
  formatDateRange: (startDate, endDate) => {
    const start = moment(startDate, "YYYY-MM-DD");
    const end = moment(endDate, "YYYY-MM-DD");

    if (!start.isValid() || !end.isValid()) {
      return "Start or date date is invalid.";
    }

    const startFormat = start.format("D MMM");
    const endFormat = end.format("D MMM");

    // Add (+1) if year changes
    const endFormatWithYear =
      start.year() !== end.year() ? `${endFormat} (+1)` : endFormat;

    return `${startFormat} to ${endFormatWithYear}`;
  },
  dismissToast: (toastId) => toast.dismiss(toastId),
  loadingToast: (msg) => {
    return toast.loading(msg, {
      toastId: "loading-toast",
      position: "bottom-end",
      autoClose: 5000,
      closeOnClick: true,
      pauseOnHover: true,
      progress: undefined,
      theme: "light",
    });
  },
  warningToast: (msg) => {
    return toast.warn(msg, {
      ...toastConfig,
      toastId: "warning-toast",
    });
  },
  successToast: (msg) => {
    return toast.success(msg, {
      ...toastConfig,
      toastId: "success-toast",
    });
  },
  errorToast: (error) => {
    const genericError = "There was an error with your request.";
    const err =
      typeof error === "string" && error.length > 0
        ? error
        : error.response?.data[0]?.msg ||
        error.response?.data?.message ||
        genericError;
    return toast.error(err, {
      ...toastConfig,
      toastId: "error-toast",
    });
  },
  userMeetsRole: (user, userRoleScopes = []) => {
    const matchesRolePermission = userRoleScopes.filter((x) =>
      user.role?.userRoleScopes.map((scope) => scope.name).includes(x)
    ).length;
    return Boolean(matchesRolePermission);
  },
  isRoleAppUser: (role) =>
    Boolean(role.userRoleScopes.find((scope) => scope.name === IS_APP_USER)),
  routeMeetsRole: (route, userRoleScopes = []) => {
    const userRolePermissions = userRoleScopes.map(
      (permission) => permission.name
    );
    const matchesRolePermission = route.scopes?.length
      ? userRolePermissions.filter((x) => route.scopes.includes(x)).length
      : true;
    return Boolean(matchesRolePermission);
  },
  sortEmployeeCrew: (x, y) => {
    return x.isLead !== y.isLead
      ? y.isLead - x.isLead
      : y.role.name !== x.role.name
        ? y.role.name < x.role.name
          ? 1
          : -1
        : y.employee && y.employee.firstName < x.employee.firstName
          ? 1
          : -1;
  },
  isSettingEnabled: (packages = [], pkgName, settingName) => {
    const pkg = packages.find((p) => p.path === pkgName);
    const setting = pkg?.settings.find((s) => s.name === settingName);
    return setting?.status;
  },
  isPackageEnabled: (packages = [], pack) => {
    const parentIsActive = (p) => {
      const parentPackage = packages.find((pp) => pp.id === p.parentPackageId);
      return parentPackage ? parentPackage.isActive : true;
    };
    const packagesPath = packages
      .filter((p) => p.isActive && parentIsActive(p))
      .map((p) => p.path);
    if (typeof pack === "object") {
      //array
      return pack.some((e) => packagesPath.indexOf(e) > -1);
    }
    return packagesPath.indexOf(pack) > -1;
  },
  isProdOrBeta: () =>
    process.env.REACT_APP_ENV === ENV_BETA ||
    process.env.REACT_APP_ENV === ENV_PROD,
  buildQueryString: (data) =>
    Object.keys(data)
      .filter((d) => data[d])
      .map((d) => `${d}=${data[d]}`)
      .join("&"),
  formatMileage: (mileage, toFixed) => `${mileage.toFixed(toFixed)} m.`,
  formatCurrency: (number, maximumFractionDigits = 2) =>
    new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      maximumFractionDigits,
    }).format(number),
  formatDecimal: (number, maximumFractionDigits = 2) =>
    new Intl.NumberFormat("en-US", { maximumFractionDigits }).format(number),
  formatPercent: (progress, total) =>
    `${total ? ((progress / total) * 100).toFixed(2) : 0}%`,
  formatDate: (date, format = "YYYY-MM-DD") =>
    (date ? moment(date) : moment()).format(format),
  formatDateTime: (date, format = "MM/DD/YYYY, h:mm a") =>
    (date ? moment(date) : moment()).format(format),
  capitalize: (text) =>
    text.charAt(0).toUpperCase() + text.toLowerCase().slice(1),
  validateEmail: (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  },
  timeFromSeconds: (secs, separator) => {
    const pad = (num) => ("0" + num).slice(-2);
    let minutes = Math.floor(secs / 60);
    const hours = Math.floor(minutes / 60);
    minutes = minutes % 60;
    secs = secs % 60;
    return separator
      ? `${pad(hours)}:${pad(minutes)}:${pad(secs)}`
      : `${hours}h ${pad(minutes)}min`;
  },
  formatTime: (seconds) => {
    const minutes = Math.floor(parseInt(seconds) / 60);
    const hh = Math.floor(minutes / 60);
    const mm = minutes % 60;
    return `${hh < 10 ? `0${hh}` : hh}h ${mm < 10 ? `0${mm}` : mm}m`;
  },
  getName: (person) => `${person.firstName} ${person.lastName}`,
  getAddress: (customerLocation) =>
    `${customerLocation?.shipToAddress} - ${customerLocation?.shipToCity}, ${customerLocation?.shipToState}, ${customerLocation?.shipToZipCode}`,
  dateRangeIntersects: (startDate1, startDate2, endDate1, endDate2) => {
    const mStartDate1 = moment(startDate1);
    const mEndDate1 = moment(endDate1);
    const range1 = moment.range(mStartDate1, mEndDate1);
    const mStartDate2 = moment(startDate2);
    const mEndDate2 = moment(endDate2);
    const range2 = moment.range(mStartDate2, mEndDate2);
    const intersects = range1.overlaps(range2);
    return intersects;
  },
  getWorkDaysGroupedByDate: (workdays) => {
    const days = workdays.reduce((p, c) => {
      if (p[c.date]) {
        p[c.date].push(c);
      } else {
        p[c.date] = [c];
      }
      return p;
    }, {});
    return days;
  },
  getExpenseStatusText: (expense) =>
    expense.status === STATUS_DECLINED
      ? `Declined by ${expense.declinedAuthor.firstName} ${expense.declinedAuthor.lastName
      } at ${sharedHelper.formatDateTime(expense.declinedAt)}`
      : expense.status === STATUS_APPROVED
        ? `Approved by ${expense.approvedAuthor.firstName} ${expense.approvedAuthor.lastName
        } at ${sharedHelper.formatDateTime(expense.approvedAt)}`
        : null,
  downloadFile: async (url, imageName = "image.png") => {
    const secureURL = url.replace("http://", "https://");
    FileSaver.saveAs(secureURL, imageName);
  },
  uploadFile: async (files) => {
    const formData = new FormData();
    formData.append("bucket", process.env.REACT_APP_IMAGES_BUCKET_NAME);
    files.forEach((file) => formData.append("files", file));
    const response = await awsApi.uploadFile(formData);
    return response;
  },
};
