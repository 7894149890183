import React, { useCallback, useEffect } from "react";
import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  horizontalListSortingStrategy,
  SortableContext,
  sortableKeyboardCoordinates,
} from "@dnd-kit/sortable";
import { Button, Col, Row } from "reactstrap";
import SectionItem from "./SectionItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import { useInspectionTemplate } from "../providers/inspectionTemplateProvider";

const Sections = () => {
  const { getSections, addSection, updateSectionsOrder, updateUnsavedChanges } =
    useInspectionTemplate();
  const sections = getSections();

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragEnd = useCallback(
    ($event) => {
      const { active, over } = $event;
      if (active?.id !== over?.id) {
        const oldIndex = sections.findIndex(
          (section) => section.id === active.id
        );
        const newIndex = sections.findIndex(
          (section) => section.id === over.id
        );
        const newSections = arrayMove(sections, oldIndex, newIndex);
        updateSectionsOrder(newSections);
      }
    },
    [updateSectionsOrder, sections]
  );

  const handleAddSection = useCallback(() => {
    addSection();
  }, [addSection]);

  useEffect(() => {
    if (!sections.length) {
      handleAddSection();
      updateUnsavedChanges(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <Row className="mt-4 border-bottom-0 mb-0 gap-3 ms-1" sm={1}>
        <SortableContext
          items={sections.map((section) => section.id)}
          strategy={horizontalListSortingStrategy}
        >
          {sections.map((section) => (
            <SectionItem
              key={section.id}
              section={section}
              className="nav-item rounded-4 rounded-end-0 m-0 d-flex align-items-center gap-4 px-3 py-2"
            />
          ))}
          <Col className="nav-item p-0">
            <Button
              size="sm"
              className="py-2 px-3 border-1 border-end-0 btn-dashed rounded-4 rounded-end-0 w-100"
              color="secondary"
              onClick={handleAddSection}
            >
              <FontAwesomeIcon icon={faCirclePlus} size="xl" /> Add Section
            </Button>
          </Col>
        </SortableContext>
      </Row>
    </DndContext>
  );
};

export default Sections;
