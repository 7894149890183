import React, { useCallback, useMemo, useState } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGripVertical,
  faEllipsisVertical,
} from "@fortawesome/free-solid-svg-icons";
import {
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Row,
  Col,
} from "reactstrap";
import { faTrash, faCopy } from "@fortawesome/free-solid-svg-icons";
import InputEditable from "./InputEditable";
import { useInspectionTemplate } from "../providers/inspectionTemplateProvider";
import DeleteModal from "./DeleteModal";

const SectionItem = ({ section, className }) => {
  const {
    updateSection,
    copySection,
    removeSection,
    setActiveTab,
    getTotalQuestionsBySection,
    getErrors,
    state: { activeTab },
  } = useInspectionTemplate();
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    setActivatorNodeRef,
    isDragging,
  } = useSortable({ id: section.id });

  const [isModalOpen, setIsModalOpen] = useState(false);

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const totalQuestions = getTotalQuestionsBySection(section.id);

  const handleUpdateName = useCallback(
    (section, $event) => {
      const value = $event.target.value;
      updateSection({ ...section, name: value });
    },
    [updateSection]
  );

  const handleCopySection = useCallback(() => {
    copySection(section);
  }, [copySection, section]);

  const handleDeleteSection = useCallback(() => {
    removeSection(section);
  }, [removeSection, section]);

  const handleDeleteSectionModal = useCallback(() => {
    setIsModalOpen((prev) => !prev);
  }, [setIsModalOpen]);

  const wrapperClassName = useMemo(() => {
    const draggingClassNames = isDragging
      ? "border-1 border-end-0 btn-dashed bg-light z-3"
      : "border-0";
    return `${className} ${draggingClassNames} section-item`;
  }, [className, isDragging]);

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
      className={wrapperClassName}
      onClick={() => setActiveTab(section.id)}
    >
      <Row
        className={`w-100 align-items-center bg-transparent p-0 m-0 border-0 rounded-0 ${
          activeTab === section.id ? "active" : ""
        }`}
      >
        <Col sm={1} className="p-0">
          <FontAwesomeIcon
            icon={faGripVertical}
            ref={setActivatorNodeRef}
            {...listeners}
            className="draggable-icon"
          />
        </Col>
        <Col sm={10} className="d-flex justify-content-start flex-column p-0">
          <InputEditable
            value={section.name}
            onChange={($event) => handleUpdateName(section, $event)}
            placeholder="Section Name"
            size="small"
            className="max-width-200"
            data-testid="section-name"
            errors={getErrors(section.id)}
          />
          <small className="text-muted fst-italic">
            {totalQuestions || 0} questions
          </small>
        </Col>
        <Col
          sm={1}
          className="d-flex justify-content-end align-items-center p-0"
        >
          <UncontrolledDropdown group className="ms-2">
            <DropdownToggle caret={false} color="white">
              <FontAwesomeIcon icon={faEllipsisVertical} />
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem
                onClick={handleCopySection}
                className="d-flex align-items-center gap-2"
              >
                <FontAwesomeIcon icon={faCopy} />
                <span className="ms-1">Copy</span>
              </DropdownItem>
              <DropdownItem
                onClick={handleDeleteSectionModal}
                className="d-flex align-items-center gap-2 text-danger"
              >
                <FontAwesomeIcon icon={faTrash} />
                <span className="ms-1">Delete</span>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Col>
      </Row>
      <DeleteModal
        title="Delete Section"
        name={section.name}
        isOpen={isModalOpen}
        onDelete={handleDeleteSection}
        onClose={() => setIsModalOpen((prev) => !prev)}
      />
    </div>
  );
};

export default SectionItem;
