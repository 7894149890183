import React, { useCallback, useState } from "react";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Collapse } from "reactstrap";
import GroupDetail from "./GroupDetail";

const SectionDetail = ({ section }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleCollapse = useCallback(($event) => {
    $event.preventDefault();
    setIsOpen(prev => !prev);
  }, []);

  return (
    <div className={`border border-1 mb-3 border-radius-md ${isOpen ? "border-light-blue" : "border-gray"}`}>
      <div
        onClick={handleCollapse}
        className={`cursor-pointer p-2 d-flex justify-content-between border-radius-md border-radius-bottom-0 align-items-center ${isOpen ? "bg-light-blue" : ""}`}
        aria-expanded={isOpen}
      >
        <div className="fw-semibold">
          {section.name} - <span className="text-gray">{section.groups.length} groups of questions</span>
        </div>
        <div>
          <FontAwesomeIcon
            className={`ms-2 rotate ${isOpen ? "rotate-90" : ""}`}
            icon={faChevronRight}
          />
        </div>
      </div>
      <Collapse isOpen={isOpen}>
        {section.groups.map((group) => (
          <GroupDetail key={group.id} group={group} />
        ))}
      </Collapse>
    </div>
  );
};

export default SectionDetail;
