import React, { useEffect, useMemo } from "react";
import { Card, CardBody, Row, Col, Button } from "reactstrap";
import { faFileCircleCheck, faEye } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InputEditable from "./InputEditable";
import {
  ORIGIN_PAGE,
  useInspectionTemplate,
} from "../providers/inspectionTemplateProvider";
import { useUpdateOrCreateTemplateFull } from "../api/Inspections.hooks";
import { useNavigate } from "react-router-dom";
import { relevantDiffDates } from "../helpers/time";

import { components, sharedHelper } from "@crewos/shared";

const { TooltipItem } = components;

const TemplateHeader = ({ origin, templateId }) => {
  const {
    state: { template, hasUnsavedChanges },
    updateTemplate,
    getData,
    updateUnsavedChanges,
    getErrors,
    verifyData,
  } = useInspectionTemplate();

  const {
    isLoading: isLoadingSaveTemplate,
    mutate: saveTemplate,
    data: templateSavedData,
  } = useUpdateOrCreateTemplateFull(templateId);

  const navigate = useNavigate();

  const handleUpdateName = React.useCallback(
    ($event) => {
      const name = $event.target.value;
      updateTemplate({ ...template, name });
    },
    [template, updateTemplate]
  );

  const handleSave = React.useCallback(async (status) => {
    const errors = verifyData();
    if (Object.keys(errors).length > 0) {
      sharedHelper.warningToast("Complete all required fields");
      return;
    }
    const { data, failedUploads } = await getData();
    if (failedUploads) {
      sharedHelper.warningToast("Failed to upload guidance attachments");
      return;
    }
    if (data && data.template) {
      await saveTemplate({ ...data.template, status });
    }
  }, [getData, saveTemplate, verifyData]);

  const handleSaveDraft = React.useCallback(async () => {
    await handleSave("DRAFT");
  }, [handleSave]);

  const handlePublish = React.useCallback(async () => {
    await handleSave("PUBLISHED");
  }, [handleSave]);

  useEffect(() => {
    if (templateSavedData) {
      updateTemplate(templateSavedData.data);
      updateUnsavedChanges(false);
      sharedHelper.successToast("Template saved");
      if (origin === ORIGIN_PAGE.CREATE) {
        navigate(`../edit/${templateSavedData.data.id}`, {
          relative: "path",
          replace: true,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateSavedData, navigate]);

  const headerMessage = useMemo(() => {
    if (hasUnsavedChanges) {
      return "You have unsaved changes";
    }
    if (isLoadingSaveTemplate) {
      return "Saving...";
    }
    if (
      !hasUnsavedChanges &&
      !isLoadingSaveTemplate &&
      origin === ORIGIN_PAGE.UPDATE
    ) {
      return `Last updated ${relevantDiffDates(
        template.updatedAt,
        new Date()
      )} ago`;
    }
    return "";
  }, [hasUnsavedChanges, isLoadingSaveTemplate, template, origin]);

  return (
    <Card className="mb-3 w-100 box-shadow-none" style={{ zIndex: 2 }}>
      <CardBody>
        <Row className="align-items-center justify-content-between">
          <Col className="d-flex align-items-center">
            <FontAwesomeIcon icon={faFileCircleCheck} size="lg" />
            <InputEditable
              className="ms-1"
              value={template.name}
              onChange={handleUpdateName}
              placeholder="Template Name"
              size="medium"
              errors={getErrors(template.id)}
            />
          </Col>
          <Col className="d-flex flex-row align-items-center justify-content-end gap-3">
            <span className="text-muted small">{headerMessage}</span>
            <TooltipItem
              id="preview-tooltip"
              title="Question Preview"
              className="d-none"
            >
              <Button
                size="sm"
                className="rounded-circle d-flex custom-rounded-button py-2"
                color="secondary"
              >
                <FontAwesomeIcon icon={faEye} />
              </Button>
            </TooltipItem>
            <Button
              color="secondary"
              onClick={handleSaveDraft}
              disabled={isLoadingSaveTemplate || !hasUnsavedChanges}
            >
              Save as Draft
            </Button>
            <Button
              color="primary"
              onClick={handlePublish}
              disabled={
                (isLoadingSaveTemplate || !hasUnsavedChanges) &&
                template.status === "PUBLISHED"
              }
            >
              Publish Template
            </Button>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default TemplateHeader;
