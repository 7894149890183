import {
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row
} from "reactstrap";
import React, { useMemo } from "react";
import SectionDetail from "./SectionDetail";

const InspectionDetailModal = ({ isOpen, inspection, onClose, status }) => {

  const templateWorkOrder = useMemo(() => {
    return inspection?.inspectionTemplateWorkOrder || {};
  }, [inspection]);

  const template = useMemo(() => {
    return templateWorkOrder?.template || {};
  }, [templateWorkOrder]);

  const sections = useMemo(() => {
    return template?.sections || [];
  }, [template]);

  const totalQuestionsBySection = useMemo(() => {
    return sections?.reduce((acc, section) => {
      acc[section?.id] = section?.groups.reduce((acc, group) => acc + group?.questions?.length, 0);
      return acc;
    }, {});
  }, [sections]);

  const totalQuestions = useMemo(() => {
    return Object.values(totalQuestionsBySection).reduce((acc, total) => acc + total, 0);
  }, [totalQuestionsBySection]);

  if (!inspection) {
    return null;
  }

  return (
    <Modal isOpen={isOpen} contentClassName="p-3 border-radius-md" size="lg">
      <ModalHeader toggle={onClose} className="border-0 align-items-start w-100" tag={({ children, className }) => <Row className={`${className} w-100`}>{children}</Row>}>
        <Col sm={10}>
          <span className="d-block fw-semibold font-size-1-1">
            {templateWorkOrder.name}
          </span>
        </Col>
        <Col sm={2}>
          <small style={status?.color} className="p-2">
            {status?.item?.name}
          </small>
        </Col>
        <Col sm={12}>
          <small className="text-gray">
            {template.name} - {totalQuestions} questions
          </small>
        </Col>
      </ModalHeader>
      <ModalBody>
        <Row sm={3}>
          <Col>
            <h5>Inspector</h5>
            <p>{inspection.createdByUser.firstName} {inspection.createdByUser.lastName}</p>
          </Col>
          <Col>
            <h5>Contact Name</h5>
            <p>{inspection.employee.firstName} {inspection.employee.lastName}</p>
          </Col>
          <Col>
            <h5>Date</h5>
            <p>{inspection.date}</p>
          </Col>
        </Row>
        <hr />
        <div className="height-vh-60 overflow-y-auto hidden-scrollbar">
          {sections.map((section) => (
            <SectionDetail section={section} key={section.id} />
          ))}
        </div>
      </ModalBody>
    </Modal>
  );
};

export default InspectionDetailModal;
